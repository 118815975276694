// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  padding-top: var(--spacer);
  padding-bottom: var(--spacer);
  margin-bottom: var(--spacer-2);
  border-bottom: 2px solid var(--border-color);
}

.masthead-title {
  margin-bottom: var(--spacer-1);
  font-weight: 400;
  font-size: 1.25rem;

  a {
    color: inherit;
    text-decoration: none;
  }

  small {
    font-weight: 400;
    opacity: .5;
  }
}
