// Typography
//
// Headings, body text, lists, and other misc typographic elements.

h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 400;
  line-height: 1.25;
  color: var(--heading-color);
}

h1 {
  font-size: 2rem;
}

h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
}

h3 {
  margin-top: 1rem;
  font-size: 1.25rem;
}

h4, h5, h6 {
  margin-top: 1rem;
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
}

hr {
  position: relative;
  margin: var(--spacer-2) 0;
  border: 0;
  border-top: 1px solid var(--border-color);
}

abbr {
  font-size: 85%;
  font-weight: bold;
  color: var(--gray-600);
  text-transform: uppercase;

  &[title] {
    cursor: help;
    border-bottom: 1px dotted var(--border-color);
  }
}

blockquote {
  padding: .5rem 1rem;
  margin: .8rem 0;
  color: var(--gray-600);
  border-left: .25rem solid var(--border-color);

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 30em) {
    padding-right: 5rem;
    padding-left: 1.25rem;
  }
}

figure {
  margin: 0;
}


// Markdown footnotes
//
// See the example content post for an example.

// Footnote number within body text
a[href^="#fn:"],
// Back to footnote link
a[href^="#fnref:"] {
  display: inline-block;
  margin-left: .1rem;
  font-weight: bold;
}

// List of footnotes
.footnotes {
  margin-top: 2rem;
  font-size: 85%;
}

// Custom type
//
// Extend paragraphs with `.lead` for larger introductory text.

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
